import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="footer">
    <div className="container page lg:max-w-lg">
      <div className="flex mb-12">
        <div className="mr-16">
          <ul>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <a
                href="https://wfhenderson.smugmug.com/The-Dream-Shop-A-Gallery-of-Work"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gallery
              </a>
            </li>
            <li>
              <Link to="/fraud-protection">Fraud Protection</Link>
            </li>
            <li>
              <Link to="/risks">Risks</Link>
            </li>
          </ul>
        </div>
        <div className="mr-32">
          <div className="md:hidden">
            <Link className="button mb-8" to="/get-started">
              Get Started
            </Link>
          </div>
          <ul>
            <li>
              <a href="https://gamewornguides.com">Game Worn Guides</a>
            </li>
            <li>
              <a
                href="https://twitter.com/MLBDreamShop"
                target="_blank"
                rel="noopener noreferrer"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/gamewornguides/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/gamewornguides/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
            </li>
            <li>
              <Link to="/get-started">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="hidden md:block">
          <h2 className="footer-cta mb-6">
            <span>Have a project in mind?</span>
            <br />
            Let's chat!
          </h2>
          <Link className="button mb-8" to="/get-started">
            Get Started
          </Link>
        </div>
      </div>
      <div className="flex justify-center">
        <span className="footer-copyright">
          &copy; 2018 The Dream Shop. All rights reserved.
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
