import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Menu from 'react-burger-menu/lib/menus/slide';

const styles = {
  bmBurgerButton: {
    display: 'none',
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'flex',
    aligntItems: 'center',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

class Nav extends React.Component {
  state = {
    isOpen: false,
  };

  toggleMenu = () => {
    this.setState(state => ({
      isOpen: !state.isOpen,
    }));
  };

  onMenuStateChange = state => {
    this.setState({ isOpen: state.isOpen });
  };

  render() {
    return (
      <nav>
        <div className="absolute pin-l pin-r z-10" style={{ height: 168 }}>
          <Menu
            right={true}
            styles={styles}
            isOpen={this.state.isOpen}
            onStateChange={this.onMenuStateChange}
            width={250}
          >
            <div className="mobile-nav">
              <ul>
                <li>
                  <Link to="/" onClick={this.toggleMenu}>
                    Home
                  </Link>
                </li>
                <li>
                  <a
                    href="https://wfhenderson.smugmug.com/The-Dream-Shop-A-Gallery-of-Work"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.toggleMenu}
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <Link to="/about" onClick={this.toggleMenu}>
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" onClick={this.toggleMenu}>
                    Pricing
                  </Link>
                </li>
                <Link
                  to="/get-started"
                  onClick={this.toggleMenu}
                  className="button button-green mt-8"
                >
                  Get Started
                </Link>
              </ul>
            </div>
          </Menu>
          <div
            className={`container page py-8 flex items-start md:items-center ${
              this.props.light ? 'nav-light' : ''
            }`}
          >
            <div className="nav-logo">
              <StaticQuery
                query={graphql`
                  query {
                    logo: file(relativePath: { eq: "dream-shop-logo.png" }) {
                      childImageSharp {
                        fixed(width: 102, height: 100) {
                          ...GatsbyImageSharpFixed_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                `}
                render={data => (
                  <Link to="/">
                    <Img fixed={data.logo.childImageSharp.fixed} />
                  </Link>
                )}
              />
            </div>
            <div className="hidden md:block nav-items ml-auto">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a
                    href="https://wfhenderson.smugmug.com/The-Dream-Shop-A-Gallery-of-Work"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
              </ul>
              <Link to="/get-started" className="button button-green">
                Get Started
              </Link>
            </div>
            <div className="md:hidden flex flex-1">
              <div className="ml-auto">
                <button className="burger-button" onClick={this.toggleMenu}>
                  <div className="burger-bar" />
                  <div className="burger-bar" />
                  <div className="burger-bar" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Nav;
